// =========================================================
// * Argon Design System Pro React - v1.0.0 based on Argon Design System Pro - v1.0.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/argon-design-system-pro-react
// * Copyright 2020 Creative Tim (https://www.creative-tim.com)
//
// Coded by www.creative-tim.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// Bootstrap Functions
@import "bootstrap/functions";

// Custom Variables
@import "custom/variables";

// Theme Variables
@import "theme/variables-pro";

// Bootstrap Core
@import "bootstrap/bootstrap";

// Theme Core
@import "theme/theme-pro";

// Custom Variables
@import "custom/styles";

// React Differences
@import "react/react-differences";
